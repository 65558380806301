@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  mat-calendar {
    --mat-datepicker-calendar-body-label-text-size: 0.8rem;

    @include mat.elevation(0);
  }
}

@mixin color($theme) {
  mat-calendar {
    --mat-datepicker-calendar-header-text-color: #0000008a;
    --mat-datepicker-calendar-body-label-text-color: #0000008a;
    --mdc-icon-button-icon-color: white;
    --mat-datepicker-calendar-period-button-icon-color: white;
  }
}

@mixin typography($theme) {
  mat-calendar {
    --mat-datepicker-calendar-body-label-text-size: 0.8rem;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}