@use "@angular/material" as mat;

//-------------------------------------------
// Clases y estilos generales
//-------------------------------------------

.width-100 {
  width: 100%;
}

.width-66 {
  width: 66%;
}

.width-50 {
  width: 50%;
}

.width-33 {
  width: 33%;
}

.height-100 {
  height: 100%;
}

.height-66 {
  height: 66%;
}

.height-50 {
  height: 50%;
}

.height-33 {
  height: 33%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  clear: both;
}

.material-shadow {
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.05), 0 4px 5px 1px rgba(0, 0, 0, 0.025), 0 2px 7px 2px rgba(0, 0, 0, 0.025);
}

.nowrap {
  word-break: normal !important;
}

.img-responsive {
  width: 100%;
}

.center-block {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.img-rounded {
  border-radius: 50% 50%;
}

a {
  color: $colorSecondary;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.truncate {
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

//-------------------------------------------
// Negrita. En Firefox no se apreciaba la negrita se sube el weight a 700
//-------------------------------------------
strong {
  font-weight: 700;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}

button.disabled {
  cursor: not-allowed !important;
  opacity: 0.38;
  pointer-events: none;
}

.mat-input-suffix {
  color: $colorGray !important;
}

button.btn-cancel {
  background-color: $colorGrayLight !important;
  color: $colorGrayDarkest !important;
}


//-------------------------------------------
// Popup Dialogs (Emergentes)
//-------------------------------------------

.popup-dialog {
  max-width: 900px !important;

  .header {
    @media (max-width: 600px) {
      padding: 12px 24px 0px 24px;
    }

    h2,
    h3 {
      font-family: $fontDefault;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 22px;

      @media (max-width: 600px) {
        font-size: 17px;
      }
    }

    h3 {
      margin-top: 5px;
      font-weight: 400;
      font-size: 17px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .mat-mdc-dialog-content {
    padding: 0;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .mat-mdc-dialog-actions {
    // margin-left: -24px;
    // margin-right: -24px;
    padding: 12px;
    background: white;
    border-top: solid 1px #ddd;
  }
}

.file-input {
  .label {
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 700;
  }

  .file-input-container {
    padding: 4px;
    background: white;
    border: solid 1px #ccc;

    .file-name {
      width: 100%;
      text-align: left;
      font-size: 13px;

      .size {
        font-size: 12px;
        color: #999;
      }
    }

    .mat-icon.delete {
      color: $colorIconNOK;
    }
  }

  .mat-hint {
    display: block;
    margin-top: 4px;
    font-size: 12px;
  }
}

//-------------------------------------------
// Table container
//-------------------------------------------

.table-container {
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05);
}

.table-container.no-shadow {
  box-shadow: none;
  border: solid 1px #ddd;
}

.table-container.no-shadow.no-border {
  box-shadow: none;
  border: none;
}

//-------------------------------------------
// Table
//-------------------------------------------

table.table {
  width: 100%;
  min-width: 700px;
  border-collapse: collapse;
  background: #fff;

  thead {
    th {
      text-align: left;
      padding: 15px;
      color: $colorGray;
      font-weight: 500;
      font-size: 13px;
      border-bottom: solid 1px #ddd;
    }

    th.filter {
      padding: 0;
      background: #fafafa;

      mat-form-field {
        min-width: 250px;
        margin: 0 24px;
        font-size: 14px;
      }

      mat-select {
        min-width: 150px;
        font-size: 14px;
      }
    }
  }

  tbody {
    mat-icon {
      color: #ccc;
    }

    td {
      padding: 5px 15px;

      button mat-icon {
        color: $colorIcon;
      }
    }

    tr:not(:last-child)>td {
      border-bottom: solid 1px #ddd;
    }
  }

  td.center,
  th.center {
    text-align: center !important;
  }

  td.right,
  th.right {
    text-align: right !important;
  }
}

table.table-striped tbody tr:nth-child(odd)>td {
  background: #fafafa;
}

//-------------------------------------------
// Mensajes generalistas
//-------------------------------------------

.info-message {
  padding: 24px;
  color: #5d3b0a;
  background: #fffae7;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px #ffefaf;

  @media(max-width:600px) {
    font-size: 12px;
  }
}

.message-ok {
  padding: 24px;
  color: rgb(26, 70, 9);
  background: #DFF0D8;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px darken(#DFF0D8, 5%);

  @media(max-width:600px) {
    font-size: 12px;
  }
}

.message-error {
  padding: 24px;
  color: rgb(90, 21, 21);
  background: #F2DEDE;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px darken(#F2DEDE, 5%);

  @media(max-width:600px) {
    font-size: 12px;
  }
}

//-------------------------------------------
// Errores
//-------------------------------------------

.error-message {
  color: $colorErrorMessage;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}



.mat-mdc-checkbox.ng-invalid.ng-touched {
  color: #f44336;

  .mat-mdc-checkbox-frame {
    border: 2px solid #f44336;
  }
}


//-------------------------------------------
// Paginacion
//-------------------------------------------

::ng-deep .web-pagination .ngx-pagination {
  .current {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    background: $colorSecondary !important;
    font-size: 13px;
    text-align: center
  }

  a {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    outline: none;
    font-size: 13px;
    text-align: center
  }

  .pagination-next a,
  .pagination-previous a,
  .pagination-next span,
  .pagination-previous span {
    width: auto;
    height: auto;
    line-height: auto;
    border-radius: 20px;
    padding: 2px 15px;
    outline: none;
    font-size: 13px;
    text-align: center
  }
}

//-------------------------------------------
// Textos
//-------------------------------------------

.text-content {
  font-size: 14px;
  color: #000;
  line-height: 1.5em;
  font-weight: 300;
  word-break: break-word;

  @media (max-width: 600px) {
    font-weight: 400 !important;
  }

  h3 {
    color: #2b2b2b;
    margin: 12px 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.4em;

    @media(max-width:600px) {
      font-size: 15px;
    }
  }

  a {
    word-break: break-word;
  }

  strong,
  b {
    font-weight: 700;
  }
}

//-------------------------------------------
// Listas
//-------------------------------------------

ul,
ol {
  @media(max-width:600px) {
    padding-left: 12px;
  }
}


//-------------------------------------------
// Captcha
//-------------------------------------------

re-captcha {
  display: block;
  margin-top: 16px;
  transform: scale(0.84);
  transform-origin: 0 0;
}

.captcha {
  margin-top: 15px;
  margin-bottom: 10px;

  .mat-error {
    font-size: 11px;
  }
}


//-------------------------------------------
// Paginacion
//-------------------------------------------

.web-pagination {
  text-align: center;

  a {
    text-decoration: none;
    outline: none;
  }
}

//-------------------------------------------
// Scrollbar
// Solo para navegadores basados en Webkit
//-------------------------------------------

.thin-scroll {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

//-------------------------------------------
// Contenidos deshabilitados por cookies
//-------------------------------------------
.content-disabled-by-cookies {
  margin: 15px 0;
  background: #f7f7f7;
  padding: 5px 15px;
  font-size: 13px;
  text-align: center;
  line-height: 1.4em;
  border: dashed 1px #ccc;
}



//----------------------------------------------------------------
//----------------------------------------------------------------
//
// Configuración de componentes
//
//----------------------------------------------------------------
//----------------------------------------------------------------


html {

  /* Sidenav */
  --mat-sidenav-container-shape: 0px;


  //--------------------------
  // Select
  //--------------------------

  --mat-select-panel-background-color: white;
  --mat-option-label-text-size: .92rem;

  mat-option {
    min-height: 42px;
  }

  .mat-mdc-select-panel {
    --mat-option-selected-state-layer-color: rgba(0, 0, 0, .12); // Color fondo opcion seleccionada
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #999; // color del check de seleccion de una opción
    min-width: 120px;
    padding: 0;
  }


  //--------------------------
  // Toggle Buttons
  //--------------------------

  mat-button-toggle-group {
    --mat-standard-button-toggle-divider-color: #ccc;
    --mat-standard-button-toggle-shape: 4px;
    --mat-standard-button-toggle-selected-state-background-color: #f0b528;
    --mat-standard-button-toggle-selected-state-text-color: #ffffff;

    .mat-pseudo-checkbox {
      display: none;
    }
  }


  //--------------------------
  // Slide Toggle
  //--------------------------

  mat-slide-toggle {
    --mdc-switch-selected-track-color: #f0b528;
  }


  //--------------------------
  // Card
  //--------------------------

  mat-card {
    --mdc-elevated-card-container-shape: 4px; // border-radius
    --mdc-elevated-card-container-color: #ffffff; // Fondo del card
    --mat-card-title-text-size: 1.285rem;
    --mat-card-title-text-weight: 700;
    --mat-card-subtitle-text-weight: 400;
    --mat-card-subtitle-text-color: #0000008A;

    padding: 16px;

    @include mat.elevation(1);
  }


  //--------------------------
  // Tooltip
  //--------------------------

  --mdc-plain-tooltip-container-shape: 4px;


  //--------------------------
  // Badge
  //--------------------------

  .mat-badge {
    --mat-badge-background-color: #e0e0e0;
    --mat-badge-text-color: #000000de;
    --mat-badge-text-weight: 400;
    --mat-badge-small-size-text-size: 9px;
    --mat-badge-small-size-container-padding: 1px 2px;
    --mat-badge-legacy-container-size: 16px;
    --mat-badge-legacy-small-size-container-size: 16px;
    --mat-badge-legacy-large-size-container-size: 16px;
    --mat-badge-container-overlap-offset: -10px; // despl. a la esquina

    .mat-badge-content {
      width: auto;
    }
  }


  //--------------------------
  // Datepicker
  //--------------------------

  --mat-datepicker-calendar-container-background-color: white;

  mat-datepicker-toggle {
    --mdc-icon-button-icon-color: #999; // Color del icono del calendario en el campo datepicker
  }

  .mat-datepicker-content {
    --mat-datepicker-calendar-container-shape: 4px; // border-radius
  }

  //--------------------------
  // Snackbar
  //--------------------------

  .mdc-snackbar {
    --mdc-snackbar-container-color: #5677c3;
    --mdc-snackbar-supporting-text-color: #fff;

    &.snackbar-ok .mat-mdc-snackbar-surface {
      --mdc-snackbar-container-color: #669933;
    }

    &.snackbar-error .mat-mdc-snackbar-surface {
      --mdc-snackbar-container-color: #f44336;
    }
  }


  //--------------------------
  // Mat-autocomplete-panel
  //--------------------------

  .mat-mdc-autocomplete-panel {
    --mat-autocomplete-background-color: white;
  }

  //-------------------------------------------
  // Tabs
  //-------------------------------------------

  ::ng-deep .mat-tab-label {
    min-width: 100px !important;
    background: #fff;
  }

  ::ng-deep .mat-tab-group.mat-primary .mat-tab-label:focus,
  .mat-tab-group.mat-primary .mat-tab-link:focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
    background-color: rgba($colorPrimary, 0.06);
  }
}

//-------------------------------------------
// TINYMCE
//-------------------------------------------

.tinymce-content {
  a {
    text-decoration: underline;
  }
}