$fontDefault: Roboto, Helvetica, sans-serif;
$fontSecondary: "Roboto Condensed", Helvetica, sans-serif;

//-----------------------------------------------
// Colores
//-----------------------------------------------

$colorPrimaryLight: #153264;
$colorPrimary: #153264;
$colorPrimaryDark: #153264;
$colorPrimaryDarkest: #153264;
$colorSecondary: #DA6132;
$colorSecondaryDark: hsl(17, 69%, 43%);
$colorThird: #DA6132;

$colorWhite: #fff;
$colorGrayLightest: #efefef;
$colorGrayLight: #ddd;
$colorGray: #999;
$colorGrayDark: #666;
$colorGrayDarkest: #333;

$colorIconOK: #8BC34A;
$colorIconNOK: #EF5350;

$colorBackgroundError: #ffdee0;
$colorErrorMessage: #ec535e;
$colorSuccessMessage: #669933;

$colorBackgroundLight: #fafafa;
$colorIcon: #727272;


//-----------------------------
// Colores Menu / submenu general
//-----------------------------

$colorMenu: white;
$colorMenuBackground: $colorPrimary;
$colorMenuBorder: transparent;
$colorMenuBackgroundHover: $colorPrimaryDark;
$colorMenuHover: white;
$colorSubMenuBorder: rgba(255, 255, 255, .15);
$colorSubMenuBackgroundEven: $colorPrimary;
$colorSubMenuBackgroundOdd: $colorPrimary;
$colorSubMenu: white;
$colorSubMenuBackgroundHover: $colorSecondary;
$colorSubMenuHover: white;
$colorMenuPrivateIcon: rgba(255, 255, 255, .35);
$colorMenuArrow: white;
$colorMenuIcon: white;
$colorSeparatorBorder: $colorPrimaryDarkest;


//-----------------------------
// Colores Menu / submenu Observatorio
//-----------------------------

$obs_colorMenu: $colorGrayDarkest;
$obs_colorMenuBackground: white;
$obs_colorMenuBorder: #ddd;
$obs_colorMenuBackgroundHover: #f7f7f7;
$obs_colorMenuHover: $colorGrayDarkest;
$obs_colorSubMenuBorder: #ddd;
$obs_colorSubMenuBackgroundEven: $colorPrimary;
$obs_colorSubMenuBackgroundOdd: $colorPrimary;
$obs_colorSubMenu: $colorGrayDarkest;
$obs_colorSubMenuBackgroundHover: $colorSecondary;
$obs_colorSubMenuHover: white;
$obs_colorMenuPrivateIcon: #ccc;
$obs_colorMenuArrow: #aaa;
$obs_colorMenuIcon: #020202;
$obs_colorSeparatorBorder: $colorPrimaryDarkest;


//-----------------------------------------------
// Mixins
//-----------------------------------------------

@mixin z-depth($depth, $opacity: 0.2) {
  box-shadow: 0 #{$depth}px #{2*$depth}px rgba(0, 0, 0, $opacity) !important;
  transition: box-shadow .3s;
}

@mixin material-shadow() {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05);
}

@mixin img-rounded($width: 40, $height: 40, $radius: 5) {
  object-fit: cover;
  width:#{$width}px;
  height:#{$height}px;
  border-radius:#{$radius}px;
}

@mixin img-square($diameter: 100) {
  object-fit: cover;
  width:#{$diameter}px;
  height:#{$diameter}px;
}

@mixin img-circle($diameter: 100) {
  object-fit: cover;
  width:#{$diameter}px;
  height:#{$diameter}px;
  border-radius:#{$diameter}px;
}

@mixin img-resize-contain($width: 320, $height: 180) {
  object-fit: cover;
  width:#{$width}px;
  height:#{$height}px;
}

// Estructuras de grid

@mixin layout-n-columns-sm($columns, $gap) {
  display: grid;
  grid-template-columns: repeat(#{$columns}, 1fr);
  gap: #{$gap};
  align-items: center;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

@mixin layout-n-columns-md($columns, $gap) {
  display: grid;
  grid-template-columns: repeat(#{$columns}, 1fr);
  gap: #{$gap};
  align-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

@mixin layout-n-columns-xs($columns, $gap) {
  display: grid;
  grid-template-columns: repeat(#{$columns}, 1fr);
  gap: #{$gap};
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}