@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  mat-form-field {
    width: 100%;

    &.ng-invalid.ng-touched {
      margin-bottom: 8px;
    }

    .mat-mdc-form-field-icon-prefix {
      padding: 0 4px 0 16px;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 8px 0 4px;

      &>.mat-icon {
        padding: 0;
      }
    }

    .mat-mdc-form-field-error-wrapper {
      padding: 0;
      top: -3px;
    }

    &.date-field {
      min-width: 200px;
      --mdc-icon-button-icon-size: 18px;
      --mdc-icon-button-state-layer-size: 32px;
    }
  }
}

@mixin color($theme) {
  mat-form-field {
    --mdc-outlined-text-field-outline-color: #ccc; // color del borde
    --mdc-outlined-text-field-disabled-input-text-color: rgba(32, 26, 26, 0.65);
    --mat-form-field-leading-icon-color: #ccc;

    // Campos de formulario read-only
    input:read-only {
      color: #999;
    }
  }
}

@mixin typography($theme) {
  mat-form-field {
    --mat-mdc-form-field-floating-label-scale: .85;
    --mat-form-field-container-text-size: 14px;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}