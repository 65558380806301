@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  .mat-mdc-dialog-panel {
    --mat-dialog-container-max-width: auto;

    .header {
      margin: 24px 24px 12px 24px;
      padding: 4px 0 4px 20px;
      border-left: solid 10px #153264;

      .mat-mdc-dialog-title {
        --mat-dialog-headline-padding: 0;

        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.4em;

        @media (max-width: 600px) {
          font-size: 18px;
        }

        .subsection {
          color: #153264;
          font-size: 18px;

          @media (max-width: 600px) {
            font-size: 15px;
          }

          &:before {
            content: '|';
            margin: 0 10px 0 5px;
            color: #999;
            font-size: 18px;

            @media (max-width: 600px) {
              font-size: 15px;
            }
          }
        }

        .mat-icon {
          color: #999;
        }
      }

      h3 {
        margin: 5px 0 0 0;
        font-weight: 400;
        font-size: 15px;
        color: #666;

        @media (max-width: 600px) {
          font-size: 14px;
          margin-top: 2px;
        }
      }

      .help {
        margin-top: -24px;
        margin-bottom: -24px;

        button.help-button mat-icon {
          color: #727272;
        }
      }
    }

    mat-dialog-container {
      --mdc-dialog-container-shape: 4px !important;
      --mdc-dialog-container-color: white;
      --mat-dialog-with-actions-content-padding: 12px 24px;
      --mat-dialog-container-elevation-shadow: var(--ig-elevation-3); // elevacion del dialog (none: dialog plano, sin sombra)

      box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
    }
  }

}

@mixin color($theme) {
  mat-dialog-content {
    --mdc-dialog-supporting-text-color: #2b2b2b;
  }
}

@mixin typography($theme) {
  mat-dialog-content {
    --mdc-dialog-supporting-text-size: 14px;
    --mdc-dialog-supporting-text-tracking: 0;
    --mdc-dialog-supporting-text-line-height: 1.4rem;

    text-overflow: balance;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}