@use "@angular/material" as mat;
@use "./m3-web-theme.scss";

@use './app/base/styles/checkbox.scss' as checkbox;
@use './app/base/styles/form-field.scss' as form-field;
@use './app/base/styles/select.scss' as select;
@use './app/base/styles/button.scss' as button;
@use './app/base/styles/dialog.scss' as dialog;
@use './app/base/styles/menu.scss' as menu;
@use './app/base/styles/chips.scss' as chips;
@use './app/base/styles/list.scss' as list;
@use './app/base/styles/expansion-panel.scss' as expansion-panel;
@use './app/base/styles/calendar.scss' as calendar;

@include mat.core();

// mat.$theme-ignore-duplication-warnings: true; // Evita warnings de duplicidad de tema

html {
  --mat-app-surface-container: white;

  @include mat.all-component-themes(m3-web-theme.$light-theme);
  @include mat.slide-toggle-color(m3-web-theme.$alternative-component-theme);

  @include mat.radio-color(m3-web-theme.$alternative-component-theme);
  @include mat.radio-density(-5);

  @include checkbox.theme(m3-web-theme.$light-theme);
  @include mat.checkbox-density(-3);

  @include form-field.theme(m3-web-theme.$light-theme);
  @include mat.form-field-density(-2);

  @include select.theme(m3-web-theme.$light-theme);
  @include button.theme(m3-web-theme.$light-theme);
  @include dialog.theme(m3-web-theme.$light-theme);
  @include menu.theme(m3-web-theme.$light-theme);
  @include chips.theme(m3-web-theme.$light-theme);
  @include list.theme(m3-web-theme.$light-theme);
  @include expansion-panel.theme(m3-web-theme.$light-theme);
  @include calendar.theme(m3-web-theme.$light-theme);
}