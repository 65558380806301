@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  mat-expansion-panel {
    --mat-expansion-container-shape: 4px;
  }
}

@mixin color($theme) {
  mat-expansion-panel {
    --mat-expansion-container-background-color: white;
  }
}

@mixin typography($theme) {
  mat-expansion-panel {
    --mat-expansion-header-text-line-height: 1.2rem;
    --mat-expansion-container-text-tracking: 0;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}