@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  mat-chip {
    --mdc-chip-container-shape-radius: 9999px;
    --mdc-chip-container-height: 24px;

    .mat-mdc-chip-remove {
      opacity: 1 !important;
    }
  }

  mat-chip-option {
    --mdc-chip-container-shape-radius: 24px;
    --mdc-chip-container-height: auto;

    .mat-mdc-chip-action {
      padding-right: 0 !important;

      .mat-mdc-chip-action-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        .mat-icon {
          font-size: 21px;
          width: 21px;
          height: 21px;
          line-height: 21px;
        }
      }
    }
  }

  mat-chip-row {
    --mdc-chip-container-shape-radius: 99px;
    --mdc-chip-outline-width: 0;
  }
}

@mixin color($theme) {
  mat-chip {
    --mdc-chip-outline-color: transparent;
    --mdc-chip-disabled-outline-color: transparent;
    --mdc-chip-focus-outline-color: transparent;
    --mdc-chip-label-text-color: #2b2b2b;
  }

  mat-chip-option {
    --mdc-chip-elevated-container-color: var(--border);
    --mdc-chip-outline-color: transparent;
  }

  mat-chip-row {
    --mdc-chip-elevated-container-color: #eee;
    --mdc-chip-with-trailing-icon-trailing-icon-color: #DA6132;
  }
}

@mixin typography($theme) {
  mat-chip {
    --mdc-chip-label-text-size: 11px;
  }

  mat-chip-option {
    --mdc-chip-label-text-size: 11px;
  }

  mat-chip-row {
    font-size: 11px !important;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}