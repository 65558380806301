@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  .mat-mdc-select-panel {
    min-width: fit-content;
    padding: 0;
  }

  .mat-mdc-option {
    .mdc-list-item__primary-text {
      width: 100%;
      white-space: nowrap;
    }
  }
}

@mixin color($theme) {
  .mat-mdc-select-panel {
    --mat-option-selected-state-layer-color: rgba(0, 0, 0, .12); // Color fondo opcion seleccionada
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #999; // color del check de seleccion de una opción
    --mat-select-panel-background-color: white;
    --mat-option-selected-state-label-text-color: #DA6132;
  }

  .mat-mdc-select {
    --mat-select-panel-background-color: white;
  }
}

@mixin typography($theme) {
  .mat-mdc-select {
    --mat-select-trigger-text-size: .92rem;
  }

  .mat-mdc-option {
    --mat-select-trigger-text-size: .92rem;
    --mat-option-label-text-size: .92rem;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}