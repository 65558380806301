// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #5677c3, tertiary: #f0b528, neutral: #007fb0
$_palettes: (
  primary: (0: #000000,
    10: #001A42,
    20: #112F61,
    25: #1F3A6C,
    30: #2B4679,
    35: #385285,
    40: #445E92,
    50: #5D76AD,
    60: #7790C8,
    70: #92ABE4,
    80: #AEC6FF,
    90: #D8E2FF,
    95: #EDF0FF,
    98: #FAF9FF,
    99: #FEFBFF,
    100: #FFFFFF),
  secondary: (0: #000000,
    10: #380D00,
    20: #5C1A00,
    25: #6E2100,
    30: #812800,
    35: #953000,
    40: #A63B0D,
    50: #C75325,
    60: #E86B3C,
    70: #FF8C61,
    80: #FFB59B,
    90: #FFDBCF,
    95: #FFEDE8,
    98: #FFF8F6,
    99: #FFFBFF,
    100: #FFFFFF),
  tertiary: (0: #000000,
    10: #380D00,
    20: #5C1A00,
    25: #6E2100,
    30: #812800,
    35: #953000,
    40: #A63B0D,
    50: #C75325,
    60: #E86B3C,
    70: #FF8C61,
    80: #FFB59B,
    90: #FFDBCF,
    95: #FFEDE8,
    98: #FFF8F6,
    99: #FFFBFF,
    100: #FFFFFF),
  neutral: (0: #000000,
    4: #000c12,
    6: #00121b,
    10: #001e2d,
    12: #002233,
    17: #002d42,
    20: #00344b,
    22: #003951,
    24: #003e58,
    25: #00405b,
    30: #004c6b,
    35: #00587c,
    40: #00658d,
    50: #007fb0,
    60: #2d9acf,
    70: #51b5ec,
    80: #82cfff,
    87: #b2e0ff,
    90: #c6e7ff,
    92: #d2ecff,
    94: #def1ff,
    95: #e4f3ff,
    96: #eaf5ff,
    98: #f6faff,
    99: #fbfcff,
    100: #ffffff,
  ),
  neutral-variant: (0: #000000,
    10: #191b23,
    20: #2e3038,
    25: #393b43,
    30: #44464f,
    35: #50525a,
    40: #5c5e67,
    50: #757780,
    60: #8f9099,
    70: #a9abb4,
    80: #c5c6d0,
    90: #e1e2ec,
    95: #f0f0fa,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_secondary: map.merge(map.get($_palettes, secondary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((color: (theme-type: light,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
      typography: (plain-family: 'Roboto',
        brand-family: 'Roboto'
      ),
      density: (scale: 0)));

/* alternative-component-theme */
$alternative-component-theme: mat.define-theme((color: (theme-type: light,
        primary: $_secondary ),
      density: (scale:-2)));