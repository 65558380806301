@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {

  .mdc-button {
    --mdc-protected-button-container-shape: 4px; // border-radius de los botones normales
    --mat-text-button-icon-spacing: 0; // Alineado de los iconos en los botones
    --mat-text-button-icon-offset: 0;
    --mdc-text-button-container-shape: 4px; // border-radius de los botones normales
    --mdc-outlined-button-container-shape: 4px; // border-radius
    --mdc-filled-button-container-shape: 4px; // border-radius
    --mdc-filled-button-container-height: 36px;
    --mat-filled-button-horizontal-padding: 20px;

    &>mat-icon {
      font-size: 24px;
      min-width: 24px;
      height: 24px;
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      opacity: .38;
      pointer-events: none;
    }
  }

  .mdc-fab {
    --mdc-fab-small-container-shape: 99px; // border-radius de los mini-fab-buttons
  }
}

@mixin color($theme) {
  .mdc-button {
    --mdc-outlined-button-label-text-color: #DA6132; // texto: color secundario de la paleta
    --mdc-outlined-button-outline-color: #DA6132; // borde: color secundario de la paleta

    &.mat-primary {
      background-color: #153264;
      color: white;
    }

    &.mat-accent {
      background-color: #DA6132;
      color: white;
    }

    &.btn-gray {
      background-color: #ddd !important;
      color: #333 !important;
    }

    &.btn-cancel {
      background-color: #ddd !important;
      color: #333 !important;
    }

    &.btn-gray-dark {
      background-color: #999 !important;
      color: white !important;
    }
  }

  .mdc-fab {
    &.mat-primary {
      --mdc-fab-small-container-color: #153264;
      --mat-fab-small-foreground-color: white;
    }

    &.mat-accent {
      --mdc-fab-small-container-color: #DA6132;
      --mat-fab-small-foreground-color: white;
    }
  }

  .mat-mdc-outlined-button {
    background: transparent !important;
    border-color: #ccc !important;

    &.mat-accent {
      color: #DA6132;
    }

    &.mat-primary {
      color: #153264;
    }

    &.mat-warn {
      border-color: #de3730 !important;
      color: #de3730;
    }
  }
}

@mixin typography($theme) {

  .mdc-button {
    --mdc-protected-button-label-text-size: 14px;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}