@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  mat-checkbox {
    display: block;

    .mat-internal-form-field {
      align-items: flex-start;

      .mdc-checkbox {
        top: -5px;
      }
    }
  }
}

@mixin color($theme) {
  mat-checkbox {
    --mdc-checkbox-selected-icon-color: #DA6132; // color del check
    --mdc-checkbox-selected-focus-icon-color: #DA6132;
    --mdc-checkbox-selected-hover-icon-color: #DA6132;
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    --mat-full-pseudo-checkbox-selected-icon-color: #DA6132;
  }
}

@mixin typography($theme) {
  mat-checkbox {
    --mat-checkbox-label-text-size: 0.9rem;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}