@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  .mat-mdc-menu-panel {
    @include mat.elevation(2);
    --mat-menu-item-icon-size: 22px;

    &.submenu-portals {
      --mat-menu-item-icon-size: 20px;

      .mat-mdc-menu-item mat-icon {
        font-size: 20px;
      }
    }
  }

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-item {
    min-height: 48px;
    text-decoration: none;

    &:hover {
      text-decoration: none !important;
    }
  }

  .divider {
    background: #ddd;
    height: 1px;
  }
}

@mixin color($theme) {
  .mat-mdc-menu-panel {
    --mat-menu-container-color: white;
    --mat-option-selected-state-layer-color: rgba(0, 0, 0, .12); // Color fondo opcion seleccionada
    --mat-menu-item-icon-color: #ccc;

    &.submenu-portals {
      --mat-menu-container-color: #DA6132;
      --mat-menu-item-label-text-color: white;
      --mat-menu-item-hover-state-layer-color: hsl(17, 69%, 43%);
      --mat-menu-item-icon-color: white;
    }
  }
}

@mixin typography($theme) {
  .mat-mdc-menu-panel {
    --mat-menu-item-label-text-size: 0.9rem;
    --mat-menu-item-label-text-weight: 400;
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}