@use 'sass:map';
@use "@angular/material" as mat;

@mixin base($theme) {
  mat-list-item {
    --mdc-list-list-item-one-line-container-height: 48px;

    @media(max-width:960px) {
      height: auto !important;
      padding: 16px 0 !important;
      font-size: 13px;
    }
  }
}

@mixin color($theme) {
  mat-list-item {
    &:not(:first-child) {
      border-top: solid 1px #ddd;
    }

    &:nth-child(even) {
      --mdc-list-list-item-container-color: white;
    }
  }
}

@mixin typography($theme) {
  mat-list-item {
    --mdc-list-list-item-label-text-weight: 600;
    --mdc-list-list-item-supporting-text-tracking: 0;
    --mdc-list-list-item-supporting-text-size: 14px;

    @media(max-width:960px) {
      --mdc-list-list-item-supporting-text-size: 13px;
      height: auto !important;
      padding: 16px 0 !important;
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, base) {
    @include base($theme);
  }

  @if mat.theme-has($theme, color) {
    @include color($theme);
  }

  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}